<template>
  <div class="baseList">
    <!-- 面包屑导航 -->
    <nf-breadcrumb
      :breadList="[{ name: '知识产权' }, { name: '基地成果', path: '/baseAchievement' }, { name: '基地列表' }]"
    ></nf-breadcrumb>
    <!-- 基地列表 -->
    <div class="baseListWrap" @click="$router.push('/download')">
      <div class="titleWrap">
        <div class="title">{{ name }}</div>
        <div class="numWrap">
          <span>基地数量：{{ baseNum }}</span>
        </div>
      </div>
      <div class="baseList">
        <div class="baseItem" v-for="baseItem in baseList" :key="baseItem.id">
          <div class="imgBox">
            <img v-if="baseItem.baseImg" :src="baseItem.baseImg" alt="" />
            <img v-else src="@/assets/images/noData.png" alt="" />
          </div>
          <div class="content">
            <div class="baseName">{{ baseItem.baseName }}</div>
            <div class="crop">主要作物：{{ baseItem.baseBreedName }}</div>
            <div class="group">{{ baseItem.baseAddress }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <nf-pagination :total="total" :currentPage="currentPage" @pageChange="pageChange"></nf-pagination>
  </div>
</template>

<script>
import merge from 'webpack-merge';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import nfPagination from '@/component/pagination/index.vue';

export default {
  name: 'baseList',
  data() {
    return {
      baseList: [], // 基地列表
      name: this.$route.query.name, // 区域名字
      baseNum: this.$route.query.showCount, // 基地数量
      // 请求参数
      params: {
        pageNum: 1,
        pageSize: 5,
        cityId: this.$route.query.id
      },
      total: 0, // 列表总数量
      currentPage: Number(this.$route.query.pageNum || 1) // 当前页
    };
  },
  methods: {
    // 获取列表
    async getList() {
      this.params.pageNum = this.$route.query.pageNum || 1;
      const api = this.$fetchApi.baseList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200' && data.result) {
        this.baseList = data.result.rows;
        this.total = data.result.total;
      }
    },
    // 页码变化
    async pageChange(page) {
      const pageNum = page.page_index;
      this.$router.push({
        query: merge(this.$route.query, {
          pageNum
        })
      });
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  components: {
    nfBreadcrumb,
    nfPagination
  }
};
</script>

<style lang="less" scoped>
.baseListWrap {
  width: 1200px;
  margin: 0 auto;
  .titleWrap {
    .title {
      font-size: 28px;
      font-weight: 900;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 1px;
      color: #333333;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .numWrap {
      font-size: 20px;
      span {
        padding-right: 40px;
      }
    }
  }
  .baseList {
    margin-top: 40px;
    .baseItem {
      overflow: hidden;
      margin-top: 40px;
      .imgBox {
        width: 270px;
        height: 169px;
        float: left;
        padding-right: 30px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .content {
        overflow: hidden;
        .baseName {
          font-size: 24px;
          padding-bottom: 30px;
        }
        .crop,
        .group {
          font-size: 20px;
        }
        .crop {
          padding-bottom: 30px;
        }
      }
    }
  }
}
</style>
